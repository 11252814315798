import { Route, Redirect } from "react-router-dom";
import { useAuth } from "lib/auth";

interface IPrivateRoute {
  [x: string]: any;
}

const PrivateRoute: React.FC<IPrivateRoute> = (props) => {
  const { user } = useAuth();
  console.log(user);
  if (user) return <Route {...props} />;
  return <Redirect to="/login" />;
};

export default PrivateRoute;
