import Admin from "features/Admin";
import Login from "features/auth/Login";
import NotFound from "features/misc/NotFound";
import { Switch, Route } from "react-router-dom";
import PrivateRoute from "./PrivateRoute";

export const Routes = () => {
  return (
    <Switch>
      <Route path="/login">
        <Login />
      </Route>
      <PrivateRoute path="/admin">
        <Admin />
      </PrivateRoute>
      <Route>
        <NotFound />
      </Route>
    </Switch>
  );
};

export default Routes;
