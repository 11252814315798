import {
  BlockOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
} from "@ant-design/icons";
import { Layout, Menu, Drawer } from "antd";
import * as React from "react";
import { ComponentType, lazy, Suspense, useState } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import s from "./index.module.sass";
import Sidebar from "./Sidebar";

const SizingManager = lazy(() => import("features/SizingManager"));

export const routes: IAdminRoute[] = [
  { label: "Sizing Manager", Component: SizingManager, path: "/admin/sizing" },
];

const { Header } = Layout;

export interface IAdminRoute {
  Component: ComponentType;
  label: string;
  path: string;
}

const Admin: React.FC = () => {
  const [collapsed, setCollapsed] = useState(false);

  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };

  const renderComponent = (route: IAdminRoute) => {
    return (
      <Route path={route.path} key={route.label} component={route.Component} />
    );
  };

  return (
    <Layout style={{ minHeight: "100vh", flexDirection: "row" }}>
      <Sidebar collapsed={collapsed} toggleCollapsed={toggleCollapsed} />
      <Layout>
        <Header
          className={s.header}
          style={{
            padding: 10,
            height: "3rem",
            background: "white",
            lineHeight: "unset",
          }}
        >
          {React.createElement(
            collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
            {
              className: s.trigger,
              onClick: toggleCollapsed,
            }
          )}
        </Header>
        <Suspense fallback="loading...">
          <Switch>
            {routes.map(renderComponent)}
            <Redirect to="/admin/sizing" />
          </Switch>
        </Suspense>
      </Layout>
    </Layout>
  );
};
export default Admin;
