import { Form, Input, Button } from "antd";
import { useAuth } from "lib/auth";
import { Redirect } from "react-router";
import s from "./index.module.sass";

interface ICredentials {
  email: string;
  password: string;
}

const Login: React.FC = () => {
  const { login, user } = useAuth();

  const onSubmit = (params: ICredentials) =>
    login({
      email: params.email,
      password: params.password,
    });

  if (user) return <Redirect to="/admin" />;

  return (
    <div className={s.login}>
      <div className={s.cont}>
        <Form onFinish={onSubmit}>
          <Form.Item
            name="email"
            rules={[{ required: true, message: "Please input your email!" }]}
          >
            <Input placeholder="email" />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[{ required: true, message: "Please input your password!" }]}
          >
            <Input.Password placeholder="password" />
          </Form.Item>

          <Form.Item>
            <Button className={s.cta} type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};
export default Login;
