import React from "react";
import AppRoutes from "routes";
import AppProviders from "providers";

import "antd/dist/antd.css";

function App() {
  return (
    <AppProviders>
      <AppRoutes />
    </AppProviders>
  );
}

export default App;
