import { sha256 } from "js-sha256";
import initAuth from "./initAuth";
import { REACT_APP_BACKEND_URL } from "lib/config";

const BACKEND_URL = REACT_APP_BACKEND_URL;

interface User {
  email: String;
  name: String;
  graphql_token?: String;
}

interface LoginCredentialsDTO {
  email: string;
  password: string;
}

const loginFn = async ({ email, password }: LoginCredentialsDTO) => {
  let resp: any = await fetch(`${BACKEND_URL}/profile/login`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ email, password: sha256(password) }),
  });
  resp = await resp.json();
  if (resp.status !== "success") throw new Error(resp.data);
  return resp.data;
};

const loadUserFn = async () => {
  let resp: any = await fetch(`${BACKEND_URL}/auth_verify`);
  resp = await resp.json();
  if (resp.status !== "success") throw new Error(resp.data);
  return resp.data;
};

const authConfig = {
  loginFn,
  loadUserFn,
};

export const { AuthProvider, useAuth } = initAuth<User, LoginCredentialsDTO>(
  authConfig
);
