import { useState, useContext, createContext, useMemo } from "react";
import { useAsync, useAsyncCallback } from "react-async-hook";
import { message } from "antd";

interface AuthContextValue<User, LoginCredentialsDTO> {
  user?: User;
  loadingUser: boolean;
  logginIn: boolean;
  login: (dto: LoginCredentialsDTO) => Promise<User>;
}

interface AuthConfig<User, LoginCredentialsDTO> {
  loginFn: (dto: LoginCredentialsDTO) => Promise<User>;
  loadUserFn: () => Promise<User>;
}

const initAuth = <User, LoginCredentialsDTO>(
  config: AuthConfig<User, LoginCredentialsDTO>
) => {
  const AuthContext = createContext<AuthContextValue<
    User,
    LoginCredentialsDTO
  > | null>(null);

  const { loginFn, loadUserFn } = config;

  const AuthProvider: React.FC = ({ children }) => {
    const [user, setUser] = useState<User | undefined>();

    const loadUser = useAsync(loadUserFn, [], {
      onSuccess: (user) => setUser(user),
      onError: (e) => message.error(e.message),
    });

    const login = useAsyncCallback(loginFn, {
      onSuccess: (user) => setUser(user),
      onError: (e) => message.error(e.message),
    });

    const value = useMemo(
      () => ({
        user,
        loadingUser: loadUser.loading,
        logginIn: login.loading,
        login: login.execute,
      }),
      [user, loadUser.loading, login.loading, login.execute]
    );

    if (user || loadUser.error)
      return (
        <AuthContext.Provider value={value}> {children} </AuthContext.Provider>
      );

    if (loadUser.loading) return <div> Loading... </div>;

    return null;
  };

  const useAuth = () => {
    const context = useContext(AuthContext);

    if (!context) {
      throw new Error(`useAuth must be used within an AuthProvider`);
    }
    return context;
  };

  return { AuthProvider, AuthConsumer: AuthContext.Consumer, useAuth };
};

export default initAuth;
