import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  createHttpLink,
  InMemoryCache,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { AuthProvider, useAuth } from "lib/auth";
import { REACT_APP_GRAPHQL_URL, REACT_APP_BACKEND_URL } from "lib/config";
import * as React from "react";
import { ErrorBoundary } from "react-error-boundary";
import { BrowserRouter as Router } from "react-router-dom";
import { RestLink } from "apollo-link-rest";
import * as Sentry from "@sentry/react";

const ErrorFallback = () => {
  return (
    <div>
      <h2>Ooops, something went wrong :( </h2>
      <button onClick={() => window.location.assign(window.location.origin)}>
        Refresh
      </button>
    </div>
  );
};

const getApolloClient = (token: String | undefined) => {
  const httpLink = createHttpLink({
    uri: REACT_APP_GRAPHQL_URL,
  });

  const restLink = new RestLink({
    uri: REACT_APP_BACKEND_URL,
    headers: {
      "Content-Type": "application/json",
    },
    // credentials: "include",
    bodySerializers: {
      encodeFormData: (data, headers) => {
        const formData = new FormData();

        Object.keys(data).forEach((key) => {
          formData.append(key, data[key]);
        });

        headers.delete("Content-Type");

        return { body: formData, headers };
      },
    },
  });

  const authLink = setContext((_, { headers }) => {
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : "",
      },
    };
  });

  const client = new ApolloClient({
    cache: new InMemoryCache(),
    link: ApolloLink.from([authLink, restLink, httpLink]),
  });

  return client;
};

// Load the apollo provider only if graphql_token is set in the user response.
const KCApolloProvider: React.FC = ({ children }) => {
  const { user } = useAuth();
  return (
    <ApolloProvider client={getApolloClient(user?.graphql_token)}>
      {children}
    </ApolloProvider>
  );
};

const AppProvider: React.FC = ({ children }) => {
  return (
    <Sentry.ErrorBoundary fallback={<ErrorFallback />}>
      <AuthProvider>
        <KCApolloProvider>
          <Router>{children}</Router>
        </KCApolloProvider>
      </AuthProvider>
    </Sentry.ErrorBoundary>
  );
};

export default AppProvider;
