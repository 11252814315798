import { Layout, Drawer, Menu } from "antd";
import { IAdminRoute, routes } from "../index";
import { BlockOutlined } from "@ant-design/icons";
import s from "./index.module.sass";

const { Sider } = Layout;

const SidebarContent = () => {
  const renderRoute = (route: IAdminRoute) => {
    return (
      <Menu.Item key={route.label} icon={<BlockOutlined />}>
        {route.label}
      </Menu.Item>
    );
  };

  return (
    <>
      <div className={s.logo} />
      <Menu theme="dark" defaultSelectedKeys={["Sizing Manager"]} mode="inline">
        {routes.map(renderRoute)}
      </Menu>
    </>
  );
};

interface ISidebar {
  collapsed: boolean;
  toggleCollapsed: () => void;
}

const Sidebar: React.FC<ISidebar> = ({ collapsed, toggleCollapsed }) => {
  return (
    <>
      <Sider
        className={s.lgSider}
        breakpoint="lg"
        trigger={null}
        collapsible
        collapsed={collapsed}
        width={200}
      >
        <SidebarContent />
      </Sider>
      <Drawer
        className={s.smSider}
        width={200}
        bodyStyle={{ padding: 0, backgroundColor: "#001529" }}
        placement="left"
        visible={!collapsed}
        onClose={toggleCollapsed}
      >
        <SidebarContent />
      </Drawer>
    </>
  );
};

export default Sidebar;
