declare global {
  interface Window {
    REACT_ENVS: { [key: string]: string | undefined };
  }
}

export const {
  REACT_APP_BACKEND_URL,
  REACT_APP_GRAPHQL_URL,
  REACT_APP_ENV,
}: any = {
  ...process.env,
  ...window.REACT_ENVS,
};
